import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from '../services/Modal'; // Import the Modal component

const generateRecoveryCode = () => {
  const words = [
    "apple", "banana", "orange", "grape", "watermelon", "kiwi", "peach", 
    "strawberry", "blueberry", "mango", "pineapple", "papaya", "cherry", 
    "pear", "plum", "apricot", "lemon", "lime", "coconut", "lychee", 
    "fig", "nectarine", "cantaloupe", "honeydew", "dragonfruit", 
    "guava", "pomegranate", "raspberry", "blackberry", "cranberry"
  ];
  return words.sort(() => 0.5 - Math.random()).slice(0, 3).join('-'); // Randomly select 3 words
};

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [recoveryCode, setRecoveryCode] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate user input
    if (username === '' || email === '' || password === '') {
      setErrorMessage('All fields are required.');
      return;
    }

    // Simulate user authentication (replace with actual authentication logic)
    // For example, you might want to call an API to validate the credentials
    const isAuthenticated = true; // Simulate successful authentication

    if (isAuthenticated) {
      // Generate a recovery code
      const code = generateRecoveryCode();
      setRecoveryCode(code); // Set the recovery code state
      setIsModalOpen(true); // Open the modal

      // Store user information in localStorage (excluding password)
      const userInfo = {
        email,
        username,
        recoveryCode: code,
        profilePicture: `https://ui-avatars.com/api/?name=${username}&background=FFD700&color=000000`
      };

      // If Remember Me is checked, store in localStorage, otherwise use sessionStorage
      if (rememberMe) {
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
      } else {
        sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
      }

      // Clear the error message
      setErrorMessage('');
      // Redirect to profile page after successful login
      navigate('/profile');
    } else {
      setErrorMessage('Invalid credentials. Please try again.'); // Update error message if authentication fails
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    navigate('/profile'); // Redirect to profile page after closing the modal
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-gray-900 to-black">
      <div className="w-full max-w-md p-8 space-y-8 bg-gray-800 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-center text-gold-500">Login</h2>
        
        {/* Display error message if any */}
        {errorMessage && <p className="text-red-500 text-center">{errorMessage}</p>}

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Username Field */}
          <div>
            <label className="block text-sm font-medium text-gold-500">Username</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              className="block w-full p-3 mt-1 border border-gold-600 rounded-md focus:outline-none focus:ring focus:ring-gold-500 text-gold-500"
              placeholder="Choose your username"
            />
          </div>

          {/* Email Field */}
          <div>
            <label className="block text-sm font-medium text-gold-500">Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="block w-full p-3 mt-1 border border-gold-600 rounded-md focus:outline-none focus:ring focus:ring-gold-500 text-gold-500"
              placeholder="you@example.com"
            />
          </div>

          {/* Password Field */}
          <div>
            <label className="block text-sm font-medium text-gold-500">Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="block w-full p-3 mt-1 border border-gold-600 rounded-md focus:outline-none focus:ring focus:ring-gold-500 text-gold-500"
              placeholder="Enter your password"
            />
          </div>

          {/* Remember Me Checkbox */}
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={rememberMe}
              onChange={() => setRememberMe(!rememberMe)}
              className="h-4 w-4 text-gold-600 border-gray-300 rounded focus:ring-gold-500"
            />
            <label className="ml-2 block text-sm text-gold-500">Remember Me</label>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full py-3 mt-4 text-white bg-gold-500 rounded-md hover:bg-gold-600 transition duration-300"
          >
            Login
          </button>
        </form>

        {/* Password Recovery Link */}
        <div className="text-center">
          <a href="/recover-password" className="text-gold-500 hover:underline">
            Forgot your password? Click here to recover it.
          </a>
        </div>

        {/* Modal for Recovery Code */}
        {isModalOpen && (
          <Modal onClose={handleModalClose}>
            <h3 className="text-lg font-bold">Recovery Code</h3>
            <p className="mt-2">Your recovery code is: <strong>{recoveryCode}</strong></p>
            <button onClick={handleModalClose} className="mt-4 px-4 py-2 bg-gold-500 text-white rounded-md hover:bg-gold-600 transition duration-300">
              Close
            </button>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default Login;