import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { getMediaDetails, getRecommendedContent, getMovieServers, getTVShowServers, getCastDetails, fetchTVShowSeasons, fetchEpisodesBySeason } from '../services/api';
import RecommendedSidebar from '../components/RecommendedSidebar';
import { FaShieldAlt, FaStar, FaClock, FaCalendarAlt, FaBookmark, FaRegBookmark } from 'react-icons/fa'; 
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import { addToFavorites, removeFromFavorites, getFavorites } from '../services/favorites'; // Import your utility functions
import { button } from 'framer-motion/client';


const WatchPage = () => {
  const { id, mediaType } = useParams();
  const [loading, setLoading] = useState(true);
  const [mediaDetails, setMediaDetails] = useState(null);
  const [recommended, setRecommended] = useState([]);
  const [videoServers, setVideoServers] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [episodes, setEpisodes] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState(1);
  const [selectedEpisode, setSelectedEpisode] = useState(1);
  const [mainCast, setMainCast] = useState([]);
  const [error, setError] = useState('');
  const [activeServer, setActiveServer] = useState(''); 
  const iframeRef = useRef(null);
  const overlayRef = useRef(null);
  const [isFavorite, setIsFavorite] = useState(false);


  const handlePlayClick = () => {
    if (iframeRef.current && overlayRef.current) {
      overlayRef.current.style.opacity = '0'; 
      iframeRef.current.style.display = 'block'; 
      iframeRef.current.style.opacity = '0'; 
      iframeRef.current.style.transition = 'opacity 0.5s ease-in'; 
  
      requestAnimationFrame(() => {
        iframeRef.current.style.opacity = '1'; 
      });
  
      setTimeout(() => {
        overlayRef.current.style.display = 'none'; 
      }, 500); 
    }
  };

  const handleServerClick = (url) => {
  iframeRef.current.src = url; 
  setActiveServer(url); 
};

  useEffect(() => {
    const fetchData = async () => {
      try {
        const details = await getMediaDetails(id, mediaType);
        setMediaDetails(details);

        const recommendations = await getRecommendedContent(id, mediaType);
        setRecommended(recommendations);

        if (mediaType === 'movie') {
          const servers = await getMovieServers(id);
          setVideoServers(servers);
        } else if (mediaType === 'tv') {
          const seasonsData = await fetchTVShowSeasons(id); 
          setSeasons(seasonsData); 

          const servers = await getTVShowServers(id, selectedSeason, selectedEpisode); 
          setVideoServers(servers);
        }

        const castDetails = await getCastDetails(id, mediaType);
        setMainCast(castDetails.slice(0, 5));

        setLoading(false);

        const favorites = getFavorites();
        const isFav = favorites.some(item => item.id === details.id);
        setIsFavorite(isFav);

      } catch (err) {
        setError('Error fetching media details');
        setLoading(false);
      }
      
    };
    

    fetchData();
  }, [id, mediaType, selectedSeason, selectedEpisode]);

  
  useEffect(() => {
    const fetchEpisodes = async () => {
      if (mediaType === 'tv') {
        const episodesData = await fetchEpisodesBySeason(id, selectedSeason);
        setEpisodes(episodesData);
      }
    };
    fetchEpisodes();
  }, [id, selectedSeason, mediaType]);

  const handleEpisodeClick = (episode) => {
    setSelectedEpisode(episode.episode_number); 
    const serverUrl = `https://embed.su/embed/tv/${id}/${selectedSeason}/${episode.episode_number}`; 

    setActiveServer(serverUrl); 
    iframeRef.current.src = serverUrl; 
    handlePlayClick(); 
  };

  if (loading) {
    return <div className="text-white text-center">Loading...</ div>;
  }

  if (error) {
    return <div className="text-white text-center">{error}</div>;
  }

  const handleFavoriteToggle = () => {
    console.log("Bookmark clicked!"); // Debugging line
    if (isFavorite) {
      removeFromFavorites(mediaDetails.id);
      console.log("Removed from favorites.");
    } else {
      addToFavorites(mediaDetails);
      console.log("Added to favorites.");
    }
    setIsFavorite(!isFavorite);
  };


  return (
    <div className="relative flex flex-col min-h-screen bg-gradient-to-b from-gray-900 to-black">
      <section  className="mb-4">
      <NavBar />
      </section>
      <div className="w-full flex flex-col items-center mt-16">
        {videoServers.length > 0 ? (
          <div className="mb-4 w-full flex flex-col items-center relative">
            <div
              className="relative w-full"
              style={{
                height: '650px',
                backgroundImage: `url(${mediaDetails?.backdrop_path ? `https://image.tmdb.org/t/p/w1280${mediaDetails.backdrop_path}` : 'fallback-image-url.jpg'})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '10px',
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.5)',
              }}
            >
              <div
                ref={overlayRef}
                className="absolute inset-0 flex items-center  justify-center cursor-pointer transition-opacity duration-500"
                style={{ opacity: 1,  }}
                onClick={handlePlayClick}
              >
                <div className="bg-black bg-opacity-60 p-4 rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-16 w-16 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.597-2.1A1 1 0 009 10.2v3.6a1 1 0 001.155.832l3.597-2.1a1 1 0 000-1.732z" />
                  </svg>
                </div>
              </div>
              <iframe
                ref={iframeRef}
                src={videoServers[0].url}
                width="100%"
                height="650"
                loading='lazy'
                autoPlay
                frameBorder="0"
                allowFullScreen
                className="rounded-lg shadow-lg transition-transform duration-400 mb-4 border border-gray-700"
                title="Player"
                style={{ display: 'none' }}
              ></iframe>
            </div>
            <p className="text-gray-400 mt-4 text-center">If the current server doesn't work, please try other servers below.</p>
          
            <div className="mt-4 flex gap-4 justify-center items-center place-self-center">
              <div className="hidden lg:block ">
                {videoServers.map((server, index) => (
                  <button
                  key={index}
                  className={`p-3 rounded-full mr-4 text-sm font-semibold transition duration-300 focus:outline-none focus:ring-2 focus:ring-gold-500 focus:ring-opacity-50 ${
                    activeServer === server.url ? 'bg-gold-500 text-black' : 'bg-gray-800 text-gold-500 hover:bg-gold-500  hover:text-black my-2'
                  } shadow-md`}
                    onClick={() => handleServerClick(server.url)}
                  >
                    {server.server}
                  </button>
                ))}
              </div>
              <div className="lg:hidden">
                <div className="relative ">
                  <select
                    className="bg-gray-800 text-gold-500 border border-gray-700 rounded-md p-2 w-full transition duration-500 ease-in-out transform hover:scale-[1.02] focus:outline-none focus:ring-2 focus:ring-gold-500 focus:ring-opacity-50 "
                    onChange={(e) => handleServerClick(e.target.value)}
                  >
                    <option value="" >Select Server</option>
                    {videoServers.map((server, index) => (
                      <option key={index} value={server.url}>
                        {server.server}
                      </option>
                    ))}
                  </select>
                  
                </div>
              </div>
            </div>
          </div>
        ) : (
          <p className="text-white">No streaming servers available for this content.</p>
        )}
      </div>


      {mediaType === 'tv' && (
       <div className="episodes-selector mt-8">
       <div className="flex items-center mb-4">
         <label htmlFor="season-dropdown" className="text-lg font-semibold mr-2 text-gold-500">Select Season:</label>
         <select
           id="season-dropdown"
           className="bg-gray-800 text-gold-500 border border-gray-700 rounded-md p-2"
           value={selectedSeason}
           onChange={(e) => {
             setSelectedSeason(Number(e.target.value));
             setSelectedEpisode(1); 
           }}
         >
           {seasons.map((season) => (
             <option key={season.season_number} value={season.season_number}>
               Season {season.season_number}
             </option>
           ))}
         </select>
       </div>
     
       <ul className="episodes-list bg-gray-900 rounded-lg p-4 max-h-60 overflow-y-auto">
         {episodes.map((episode) => (
           <li key={episode.id} className="flex justify-between items-center p-2 hover:bg-gray-800 rounded-md cursor-pointer" onClick={() => handleEpisodeClick(episode)}>
             <span className="text-gold-500">Episode {episode.episode_number}: {episode.name}</span>
             <span className="text-gray-400">{episode.air_date}</span>
           </li>
         ))}
       </ul>
     </div>
      )}

      <div className="w-full max-w-screen-xl mx-auto p-4 flex flex-col md:flex-row gap-6 mt-8 z-1">
        <div className="relative flex flex-col md:flex-row z-1 items-center">
          <img
            src={`https://image.tmdb.org/t/p/w300${mediaDetails?.poster_path}`}
            alt={mediaDetails?.title || mediaDetails?.name}
            className="w-72 h-auto rounded-lg z-1 shadow-lg transition-transform duration-300 hover:scale-105 md:w-80 lg:w-96"
          />
          <div className="text-white space-y-4 ml-4  relative">
            <section className='z-10 flex flex-wrap gap-4 relative  '>
            <h2 className="text-4xl font-bold mb-2 hover:text-gold-500">{mediaDetails?.title || mediaDetails?.name}</h2>
            <div
            className="favorite-icon scale-110 mt-3 ml-2"
            onClick={handleFavoriteToggle}
            style={{ cursor: 'pointer', zIndex: 10 }} // Ensure it's clickable
          >
            {isFavorite ? (
              <FaBookmark className="text-yellow-500 text-2xl hover:text-yellow-400 transition duration-150" />
            ) : (
              <FaRegBookmark className="text-yellow-500 text-2xl hover:text-yellow-400 transition duration-150" />
            )}
          </div>
          </section>
            <div className="flex items-center space-x-2 mb-2">
              <div className="flex items-center bg-gray-800 text-white px-3 py-1 rounded-full text-sm font-semibold shadow-md transition duration-200">
                <FaShieldAlt className="text-yellow-500 mr-1" />
                {mediaDetails?.adult ? 'R' : 'PG-13'}
              </div>
              <div className="flex items-center bg-gray-800 text-white px-3 py-1 rounded-full text-sm font-semibold shadow-md transition duration-200">
                <FaStar className="text-yellow-500 mr-1" />
                {mediaDetails?.vote_average || 'N/A'}
              </div>
              {mediaDetails?.runtime && (
                <div className="flex items-center bg-gray-800 text-white px-3 py-1 rounded-full text-sm font-semibold shadow-md transition duration-200">
                  <FaClock className="text-yellow-500 mr-1" />
                  {mediaDetails.runtime} min
                </div>
              )}
              {mediaDetails?.episode_run_time && (
                <div className="flex items-center bg-gray-800 text-white px-3 py-1 rounded-full text-sm font-semibold shadow-md transition duration-200">
                  <FaClock className="text-yellow-500 mr-1" />
                  {mediaDetails.episode_run_time[0]} min
                </div>
              )}
              {mediaDetails?.release_date && (
                <div className="flex items-center bg-gray-800 text-white px-3 py-1 rounded-full text-sm font-semibold shadow-md transition duration-200">
                  <FaCalendarAlt className="text-yellow-500 mr-1" />
                  {new Date(mediaDetails.release_date).getFullYear()}
                </div>
              )}
              {mediaDetails?.first_air_date && (
                <div className="flex items-center bg-gray-800 text-white px-3 py-1 rounded-full text-sm font-semibold shadow-md transition duration-200">
                  <FaCalendarAlt className="text-yellow-500 mr-1" />
                  {new Date(mediaDetails.first_air_date).getFullYear() || 'N/A'}
                </div>
              )}
            </div>
            <p className="leading-relaxed mb-4 text-xs text-gray-400 hover:text-gold-500">
              {mediaDetails?.overview}
            </p>
            <div className="space-y-2">
              <div className="flex items-center">
                <p className="text-gray-400 font-semibold w-20">Country:</p>
                <p className="text-white  hover:text-gold-500">{mediaDetails?.production_countries?.map((c) => c.name).join(', ') || 'N/A'}</p>
              </div>
              <div className="flex items-center">
                <p className="text-gray-400 font-semibold w-20">Genre:</p>
                <p className="text-white hover:text-gold-500">{mediaDetails?.genres?.map((genre) => genre.name).join(', ') || 'N/A'}</p>
              </div>
              <div className="flex items-center">
                <p className="text-gray-400 font-semibold w-20">Year:</p>
                <p className="text-white hover:text-gold-500">{mediaDetails?.release_date?.slice(0, 4) || mediaDetails?.first_air_date?.slice(0,4) || 'N/A'}</p>
              </div>
              <div className="flex items-center">
                <p className="text-gray-400 font-semibold w-20">Director:</p>
                <p className="text-white hover:text-gold-500">
                  {mediaDetails?.credits?.crew?.find((person) => person.job === 'Director')?.name || 'Unknown'}
                </p>
              </div>
              <div className="flex items-center">
                <p className="text-gray-400 font-semibold w-20">Stars:</p>
                <p className="text-white hover:text-gold-500">{mainCast.map((actor) => actor.name).join(', ') || 'N/A'}</p>
              </div>
            </div>
          </div>
          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-75 transition-opacity duration-500 ease-in-out z-0"></div>
        </div>

 <div className="w-full rounded-lg shadow-lg md:w-1/2 ml-35 space-x-4 place-self-end">
          <RecommendedSidebar content={recommended} />
        </div>
      </div>

      <div className="w-full max-w-screen-xl mx-auto p-4 mt-8">
        <h3 className="text-2xl text-white font-semibold mb-4">Main Cast</h3>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6">
          {mainCast.length > 0 ? (
            mainCast.map((actor) => (
              <div key={actor.id} className="flex flex-col items-center">
                <img
                  src={`https://image.tmdb.org/t/p/w92${actor.profile_path}`}
                  alt={actor.name}
                  className="w-24 h-24 rounded-full object-cover shadow-lg transition-transform duration-200 hover:scale-105"
                />
                <p className="text-white mt-2 font-semibold hover:text-gold-500">{actor.name}</p>
                <p className="text-sm text-gray-400 hover:text-gold-500">{actor.character}</p>
              </div>
            ))
          ) : (
            <p className="text-white">No main cast information available.</p>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default WatchPage;