// utils/favorites.js
export const addToFavorites = (item) => {
    const favorites = getFavorites();
    const favoriteItem = {
      id: item.id,
      title: item.title || item.name,
      media_type: item.media_type || (item.title ? 'movie' : 'tv'), // Set media_type
      poster: item.poster_path ? `https://image.tmdb.org/t/p/w500${item?.poster_path}` : 'default-poster.png', // Ensure poster URL is complete
    };
    favorites.push(favoriteItem);
    localStorage.setItem('favorites', JSON.stringify(favorites));
  };
  
  export const removeFromFavorites = (id) => {
    const favorites = JSON.parse(localStorage.getItem('favorites')) || [];
    const updatedFavorites = favorites.filter(item => item.id !== id);
    localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
  };
  
  export const getFavorites = () => {
    return JSON.parse(localStorage.getItem('favorites')) || [];
  };