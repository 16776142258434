import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const RecoverPassword = () => {
  const [recoveryCode, setRecoveryCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();


  const handleRecover = (e) => {
    e.preventDefault();
    const userInfo = JSON.parse(localStorage.getItem('userInfo')) || JSON.parse(sessionStorage.getItem('userInfo'));

    if (!userInfo) {
      setErrorMessage('No user information found. Please log in first.');
      return;
    }

    if (recoveryCode !== userInfo.recoveryCode) {
      setErrorMessage('Invalid recovery code. Please try again.');
      return;
    }

    // Update the password (this is a placeholder; implement actual password update logic)
    // For example, you might want to store the new password securely
    setSuccessMessage('Your password has been successfully updated.');
    setErrorMessage('');
    setTimeout(() => {
      navigate('/login'); // Redirect to login after successful recovery
    }, 2000);
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-black">
      <div className="w-full max-w-md p-8 space-y-8 bg-gray-800 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-center text-gold-500">Recover Password</h2>

        {errorMessage && <p className="text-red-500 text-center">{errorMessage}</p>}
        {successMessage && <p className="text-green-500 text-center">{successMessage}</p>}

        <form onSubmit={handleRecover} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gold-500">Recovery Code</label>
            <input
              type="text"
              value={recoveryCode}
              onChange={(e) => setRecoveryCode(e.target.value)}
              required
              className="block w-full p-3 mt-1 border border-gold-600 rounded-md focus:outline-none focus:ring focus:ring-gold-500 text-gold-500"
              placeholder="Enter your recovery code"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gold-500">New Password</label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              className="block w-full p-3 mt-1 border border-gold-600 rounded-md focus:outline-none focus:ring focus:ring-gold-500 text-gold-500"
              placeholder="Enter your new password"
            />
          </div>

          <button
            type="submit"
            className="w-full p-3 mt-4 text-gold-500 bg-black rounded-md hover:bg-gold-500 hover:text-black focus:outline-none focus:ring focus:ring-gold-500"
          >
            Reset Password
          </button>
        </form>
      </div>
    </div>
  );
};

export default RecoverPassword;