import React, { useState, useEffect } from 'react';
import { fetchLatestMovies } from '../services/api';
import { FaStar, FaPlay, FaShieldAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const LatestM = () => {
  const [latestMovies, setLatestMovies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAdultContent, setShowAdultContent] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const loadLatestMovies = async () => {
      try {
        setLoading(true);
        const movies = await fetchLatestMovies();
        
        // Filter out adult content if not enabled
        const filteredMovies = showAdultContent 
          ? movies 
          : movies.filter(movie => !movie.adult);

        setLatestMovies(filteredMovies);
        setLoading(false);
      } catch (error) {
        console.error('Error loading latest movies:', error);
        setLoading(false);
      }
    };

    loadLatestMovies();
  }, [showAdultContent]);

  const handleMovieClick = (movie) => {
    navigate(`/watch/movie/${movie.id}`);
  };

  const toggleAdultContent = () => {
    setShowAdultContent(!showAdultContent);
  };

  if (loading) {
    return (
      <div className="w-full py-10 flex justify-center items-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gold-500"></div>
      </div>
    );
  }

  return (
    <section className="px-4 md:px-8 lg:px-16 py-8 bg-dark-background">
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center space-x-4">
          <h2 className="text-2xl md:text-3xl font-bold text-gold-500 tracking-wide">
            Latest Movies
          </h2>
          
          {/* Adult Content Toggle */}
          <div 
            className="flex items-center cursor-pointer"
            onClick={toggleAdultContent}
            title={showAdultContent ? "Hide Adult Content" : "Show Adult Content"}
          >
            <div className={`
              w-12 h-6 rounded-full relative transition-colors duration-300
              ${showAdultContent ? 'bg-red-500' : 'bg-gray-300'}
            `}>
              <div className={`
                absolute top-1 w-4 h-4 rounded-full bg-white 
                transition-transform duration-300
                ${showAdultContent ? 'translate-x-6' : 'translate-x-1'}
              `}></div>
            </div>
            <FaShieldAlt 
              className={`ml-2 ${showAdultContent ? 'text-red-500' : 'text-gray-400'}`} 
            />
          </div>
        </div>
        <button className="text-gold-400 hover:text-gold-500 transition-colors text-sm"
          onClick={() => navigate('/movies')} >
          More
        </button>
      </div>

      <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-6 gap-4">
        {latestMovies.slice(0, 18).map((movie) => (
          <div 
            key={movie.id} 
            className="relative group cursor-pointer"
            onClick={() => handleMovieClick(movie)}
          >
            <div className="relative overflow-hidden rounded-xl shadow-xl transition-all duration-300 
                            border border-transparent group-hover:border-gold-500 
                            group-hover:scale-105 group-hover:shadow-2xl">
              {/* Adult Content Indicator */}
              {movie.adult && (
                <div className="absolute top-2 left-2 z-10 
                                bg-red-500 text-white 
                                px-2 py-1 rounded-full 
                                text-xs font-bold">
                  18+
                </div>
              )}

              {/* Poster Image */}
              <div className="relative pt-[150%] overflow-hidden"> 
                <img 
                  src={`https://image.tmdb.org/t/p/w500${movie.poster_path}`} 
                  alt={movie.title}
                  className={`absolute inset-0 w-full h-full object-cover 
                             opacity-90 group-hover:opacity-100 
                             transition-opacity duration-300 
                             transform group-hover:scale-110
                             ${movie.adult ? 'filter brightness-50' : ''}`}
                />
                
                {/* Hover Overlay */}
                <div className="absolute inset-0 bg-black/0 group-hover:bg-black/30 
                                transition-all duration-300 flex items-center justify-center">
                  <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    <FaPlay className="text-gold-500 text-3xl 
                                      transform hover:scale-110 
                                      transition-transform" />
                  </div>
                </div>
                
                {/* Rating Overlay */}
                <div className="absolute top-2 right-2 
                                bg-black/60 rounded-full 
                                px-2 py-1 flex items-center">
                  <FaStar className="text-gold-500 mr-1 text-xs" />
                  <span className="text-xs text-gold-400 font-bold">
                    {movie.vote_average ? movie.vote_average.toFixed(1) : 'N/A'}
                  </span>
                </div>
              </div>

              {/* Title Overlay */}
              <div className="absolute bottom-0 left-0 right-0 
                              bg-gradient-to-t from-black/80 to-transparent 
                              p-2">
                <h3 className="text-xs font-semibold text-gold-400 p-2 bg-black bg-opacity-50 rounded-full truncate hover:bg-gold-600 hover:text-black transition duration-600">
                {movie.title}
                </h3>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
export default LatestM;