import axios from 'axios';



// TMDB API base URL and key
const TMDB_API_KEY = process.env.REACT_APP_TMDB_API_KEY;
const TMDB_BASE_URL = 'https://api.themoviedb.org/3/';


export const getSearchSuggestions = async (query) => {
  if (!query) return [];
  try {
    const response = await axios.get(`${TMDB_BASE_URL}search/multi`, {
      params: {
        api_key: TMDB_API_KEY,
        query: query,
        language: 'en-US',
        include_adult: true,
      },
    });
    return response.data.results || [];
  } catch (error) {
    console.error('Error fetching search suggestions', error);
    return [];
  }
};

// Function to search movies, TV shows, and anime
export const searchContent = async (query) => {
  if (!query) return { movies: [], tvShows: [], anime: [] };
  try {
    const response = await axios.get(`${TMDB_BASE_URL}search/multi`, {
      params: {
        api_key: TMDB_API_KEY,
        query: query,
        language: 'en-US',
        include_adult: true,
      },
    });
    const movies = response.data.results.filter(item => item.media_type === 'movie');
    const tvShows = response.data.results.filter(item => item.media_type === 'tv');
    const anime = tvShows.filter(item => item.genre_ids.includes(16)); // TMDB categorizes anime under TV shows by genre ID
    return { movies, tvShows, anime };
  } catch (error) {
    console.error('Error fetching search results', error);
    return { movies: [], tvShows: [], anime: [] };
  }
};