// Pagination.jsx
import React from 'react';

const Pagination = ({ currentPage, onPageChange }) => {
  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    onPageChange(currentPage + 1);
  };

  return (
    <div className="flex justify-center mt-6 mb-10">
      <button 
        onClick={handlePrevious} 
        disabled={currentPage === 1}
        className={`px-4 py-2 mx-1 rounded-full shadow-md shadow-neutral-900 ${currentPage === 1 ? 'bg-gray-600' : 'bg-gold-500 hover:bg-gold-600 text-black'}`}
      >
        Previous
      </button>
      <span className="mx-2 text-xl text-gold-400 mt-2">Page {currentPage}</span>
      <button 
        onClick={handleNext} 
        className="px-4 py-2 mx-1 rounded-full bg-gold-500 hover:bg-gold-600 text-black"
      >
        Next
      </button>
    </div>
  );
};

export default Pagination;