import React, { useState, useEffect } from 'react';
import CustomCarousel from '../components/CustomCarousel';
import { fetchMoviesFromTMDB, fetchTVShowsFromTMDB, fetchAnimeFromTMDB } from '../services/api'; 
import { Link, useNavigate } from 'react-router-dom';
import { getSearchSuggestions, searchContent } from '../services/search';
import Footer from '../components/Footer';
import NavBar from '../components/NavBar';
import CustomTrending from '../components/CustomTrending';
import MustWatch from '../components/MustWatch';
import { MdWhatshot } from 'react-icons/md';
import PopularM from '../components/PopularM';
import PopularT from '../components/PopularT';
import PopularA from '../components/PopularA';
import LatestM from '../components/LatestM';
import LatestT from '../components/LatestT';
import LatestA from '../components/LatestA';

const HomePage = () => {
  const [movies, setMovies] = useState([]);
  const [tvShows, setTvShows] = useState([]);
  const [anime, setAnime] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState({ movies: [], tvShows: [], anime: [] });
  const [suggestions, setSuggestions] = useState([]);

  const navigate = useNavigate();

  // Fetch search suggestions on query change
  useEffect(() => {
    const fetchSuggestions = async () => {
      if (searchQuery.length > 0) {
        const results = await getSearchSuggestions(searchQuery);
        setSuggestions(results);
      } else {
        setSuggestions([]);
      }
    };
    fetchSuggestions();
  }, [searchQuery]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    const results = await searchContent(searchQuery);
    setSearchResults(results);
    navigate(`/search?query=${searchQuery}`);
  };

  const handleSuggestionClick = (suggestion) => {
    const mediaType = suggestion.media_type === 'movie' ? 'movie' : suggestion.genre_ids.includes(16) ? 'anime' : 'tv';
    navigate(`/watch/${mediaType}/${suggestion.id}`);
  };

  useEffect(() => {
    const loadData = async () => {
      const moviesData = await fetchMoviesFromTMDB();
      setMovies(moviesData);
      const tvShowsData = await fetchTVShowsFromTMDB();
      setTvShows(tvShowsData);
      const animeData = await fetchAnimeFromTMDB();
      setAnime(animeData);
    };
    loadData();
  }, []);

  return (
    <div className="bg-gray-900 text-white min-h-screen w-full overflow-x-hidden">
    {/* Navbar */}
    <div className='z-10 w-full'>
      <NavBar />     
    </div>
  
    {/* Main Featured Carousel Section */}
    <section className="mt-8 w-full px-2 sm:px-3 md:px-1">
      <CustomCarousel
        items={[...movies.slice(0, 4), ...tvShows.slice(0, 4), ...anime.slice(0, 4)]}
      />
    </section>

    {/* Trending Section */}
    <section className="mt-12 w-full px-2 sm:px-4 md:px-6">
      <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-center mb-8 text-yellow-500 flex items-center justify-center">
        Trending <MdWhatshot className="ml-2 text-yellow-500 text-xl sm:text-2xl md:text-3xl" />
      </h2>
      <CustomTrending />
    </section>

    {/* Sections with responsive padding */}
    <section className="mt-12 px-2 sm:px-4 md:px-6">
      <MustWatch />
    </section>
    
    <section className="mt-12 px-2 sm:px-4 md:px-6">
      <PopularM />
    </section>

    <section className="mt-12 px-2 sm:px-4 md:px-6">
      <LatestM />
    </section>
    
    <section className="mt-12 px-2 sm:px-4 md:px-6">
      <PopularT />
    </section>

    <section className="mt-12 px-2 sm:px-4 md:px-6">
      <LatestT />
    </section>  

    <section className="mt-12 px-2 sm:px-4 md:px-6">
      <PopularA />
    </section>

    <section className="mt-12 px-2 sm:px-4 md:px-6">
      <LatestA />
    </section>
    
    {/* Footer */}
    <Footer />
  </div>
);
};

export default HomePage;