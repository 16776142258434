// ContactUs.jsx
import React from 'react';

const ContactUs = () => {
  return (
    <div className="bg-gradient-to-b from-gray-900 to-black text-white min-h-screen p-6">
      <h1 className="text-center text-4xl font-bold mb-8">Contact Us</h1>
      
      <p className="text-center text-lg mb-6">
        We would love to hear from you! Whether you have questions, feedback, or need support, feel free to reach out to us.
      </p>

      <div className="max-w-md mx-auto bg-gray-800 rounded-lg p-6 shadow-lg">
        <h2 className="text-2xl font-semibold mb-4">Get in Touch</h2>
        
        <form>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1" htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              className="w-full p-2 bg-gray-700 border border-gray-600 rounded focus:outline-none focus:ring focus:ring-gold-500"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium mb-1" htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              className="w-full p-2 bg-gray-700 border border-gray-600 rounded focus:outline-none focus:ring focus:ring-gold-500"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium mb-1" htmlFor="message">Message</label>
            <textarea
              id="message"
              rows="4"
              className="w-full p-2 bg-gray-700 border border-gray-600 rounded focus:outline-none focus:ring focus:ring-gold-500"
              required
            ></textarea>
          </div>

          <button
            type="submit"
            className="w-full bg-gold-500 text-gray-900 font-bold py-2 rounded hover:bg-gold-400 transition duration-200"
          >
            Send Message
          </button>
        </form>
      </div>

      <div className="mt-8 text-center">
        <h2 className="text-2xl font-semibold mb-4">Other Ways to Reach Us</h2>
        <p className="mb-2">
          Email: <span className="font-bold text-gold-500">support@streamify.com</span>
        </p>
        <p className="mb-2">
          Phone: <span className="font-bold text-gold-500">(123) 456-7890</span>
        </p>
        <p>
          Follow us on social media for updates:
        </p>
        <div className="flex justify-center space-x-4 mt-4">
          <a href="https://twitter.com/streamify" target="_blank" rel="noopener noreferrer" className="text-gold-500 hover:text-gold-400">Twitter</a>
          <a href="https://instagram.com/streamify" target="_blank" rel="noopener noreferrer" className="text-gold-500 hover:text-gold-400">Instagram</a>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;