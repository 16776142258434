// src/components/FilterComponent.jsx
import React, { useState, useEffect } from 'react';
import { fetchGenres } from '../services/api';
import { FaFilter, FaCalendarAlt, FaTags } from 'react-icons/fa';

const FilterComponent = ({ onFilterChange }) => {
  const [genres, setGenres] = useState([]);
  const [selectedGenre, setSelectedGenre] = useState('');
  const [releaseYear, setReleaseYear] = useState('');
  const [mediaType, setMediaType] = useState('');

  useEffect(() => {
    const loadGenres = async () => {
      const genreData = await fetchGenres();
      setGenres(genreData);
    };
    loadGenres();
  }, []);

  const handleFilterChange = () => {
    onFilterChange({ genre: selectedGenre, year: releaseYear, type: mediaType });
  };

  return (
    <div className="bg-gray-800 p-4 rounded-lg shadow-lg mb-8 flex flex-wrap items-center justify-between">
      <h3 className="text-lg font-semibold text-gold-500 mb-4 w-full">Filter Results</h3>
      <div className="flex space-x-4">
        <div className="flex items-center">
          <FaTags className="text-gold-400 mr-2" />
          <select
            className="bg-gray-900 text-gold-300 border border-gold-500 rounded p-2"
            value={selectedGenre}
            onChange={(e) => {
              setSelectedGenre(e.target.value);
              handleFilterChange();
            }}
          >
            <option value="">All Genres</option>
            {genres.map((genre) => (
              <option key={genre.id} value={genre.id}>
                {genre.name}
              </option>
            ))}
          </select>
        </div>
        
        <div className="flex items-center">
          <FaCalendarAlt className="text-gold-400 mr-2" />
          <input
            type="number"
            placeholder="Release Year"
            className="bg-gray-900 text-gold-300 border border-gold-500 rounded p-2"
            value={releaseYear}
            onChange={(e) => {
              setReleaseYear(e.target.value);
              handleFilterChange();
            }}
          />
        </div>

        <div className="flex items-center">
          <FaFilter className="text-gold-400 mr-2" />
          <select
            className="bg-gray-900 text-gold-300 border border-gold-500 rounded p-2"
            value={mediaType}
            onChange={(e) => {
              setMediaType(e.target.value);
              handleFilterChange();
            }}
          >
            <option value="">All Types</option>
            <option value="movie">Movies</option>
            <option value="tv">TV Shows</option>
            <option value="anime">Anime</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default FilterComponent;