// AboutUs.jsx
import React from 'react';

const AboutUs = () => {
  return (
    <div className="bg-gradient-to-b from-gray-900 to-black text-white min-h-screen p-6">
      <h1 className="text-center text-4xl font-bold  mb-8">About Us</h1>
      <p className="text-center text-lg mb-6">
        Welcome to <span className="font-bold text-gold-500">Streamify</span>, your ultimate destination for discovering and enjoying an extensive collection of movies, TV shows, and anime.
      </p>
      
      <h2 className="text-2xl font-semibold mb-4">Our Mission</h2>
      <p className="mb-6">
        At <span className="font-bold text-gold-500">Streamify</span>, our mission is to bring the world of entertainment to your fingertips. We strive to create a user-friendly platform that allows you to explore, discover, and enjoy a diverse range of content from various genres and cultures.
      </p>

      <h2 className="text-2xl font-semibold mb-4">Why Choose Us?</h2>
      <ul className="list-disc list-inside mb-6">
        <li className="mb-2">
          <strong>Extensive Library:</strong> We offer a vast library of movies, TV shows, and anime, catering to all tastes and preferences.
        </li>
        <li className="mb-2">
          <strong>User-Friendly Interface:</strong> Our intuitive and easy-to-navigate interface ensures hassle-free browsing and streaming.
        </li>
        <li className="mb-2">
          <strong>High-Quality Streaming:</strong> Experience stunning high-definition streaming options for crystal-clear visuals and audio.
        </li>
        <li className="mb-2">
          <strong>Personalized Recommendations:</strong> Discover new favorites with our intelligent recommendation system tailored to your viewing habits.
        </li>
        <li className="mb-2">
          <strong>Regular Updates:</strong> Stay up-to-date with new releases added regularly to our library.
        </li>
        <li className="mb-2">
          <strong>Community Engagement:</strong> Join a vibrant community of fellow entertainment enthusiasts and share your thoughts and recommendations.
        </li>
        <li className="mb-2">
          <strong>Multi-Device Compatibility:</strong> Enjoy seamless streaming on smartphones, tablets, smart TVs, and computers.
        </li>
        <li className="mb-2">
          <strong>Affordable Subscription Plans:</strong> Quality entertainment should be accessible to everyone, and our flexible plans cater to various budgets.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4">Join Us Today!</h2>
      <p className="mb-6">
        At <span className="font-bold text-gold-500">Streamify</span>, we are passionate about bringing you the best in entertainment. Join our growing community of viewers and elevate your streaming experience.
      </p>
      <p className="mb-6">
        Sign up today and start exploring the world of movies, TV shows, and anime like never before!
      </p>
      <p className="text-center">
        For any inquiries, feedback, or support, please do not hesitate to contact us at <span className="font-bold text-gold-500">support@streamify.com</span>.
      </p>
      <p className="text-center mt-8">
        Thank you for choosing <span className="font-bold text-gold-500">Streamify</span>—where entertainment meets convenience!
      </p>

    </div>

    
  );
};

export default AboutUs;