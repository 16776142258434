import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaBars, FaSearch, FaStar } from 'react-icons/fa';
import { getSearchSuggestions } from '../services/search';

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const isHome = location.pathname === '/home';

  const handleSearchChange = async (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    if (query) {
      const results = await getSearchSuggestions(query);
      setSuggestions(results.slice(0, 5)); // Limit suggestions to 5
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (id, mediaType) => {
    navigate(`/watch/${mediaType}/${id}`);
    setSearchQuery('');
    setSuggestions([]);
  };

  const handleViewMore = () => {
    navigate(`/search?query=${searchQuery}`);
    setSuggestions([]);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    navigate(`/search?query=${searchQuery}`);
    setSuggestions([]);
  };

  return (
    <nav className={`fixed top-0 left-0 w-full ${isHome ? 'bg-black bg-opacity-30' : 'bg-black'} text-gold-300 p-4 flex flex-wrap justify-between items-center shadow-md z-50 sm:flex-nowrap sm:space-x-4`}>
      <div className="flex items-center justify-between sm:w-auto  w-full ">
        {/* Hamburger Menu Button */}
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="text-gold-500 p-2 rounded-md focus:outline-none transition duration-500 transform flex flex-col justify-center items-center space-y-1 hover:text-gold-600 "
        >
          {/* Hamburger Icon with Animation */}
          <div
            className={`w-7 h-0.5 bg-gold-500 transition-all duration-500 transform ${isOpen ? 'rotate-45 translate-y-2' : ''}`}
          ></div>
          <div
            className={`w-7 h-0.5 bg-gold-500 transition-all duration-500 transform ${isOpen ? 'opacity-0' : ''}`}
          ></div>
          <div
            className={`w-7 h-0.5 bg-gold-500 transition-all duration-500 transform ${isOpen ? '-rotate-45 -translate-y-2' : ''}`}
          ></div>
        </button>
        <Link to="/" className="text-gold-500 font-bold text-2xl hover:text-gold-600 duration-400">
          Streamify
        </Link>

        {/* Login Button for Mobile */}
        <Link 
          to="/login" 
          className="sm:hidden border border-gold-600 text-gold-500 rounded-full px-4 py-1 
            hover:bg-gold-600 hover:text-black transition duration-500 "
        >
          Login →
        </Link>
      
      </div>

      

      {isOpen && (
        <div className="absolute top-full mt-2 left-4 bg-yellow-500 text-black rounded-lg shadow-md w-40 py-2 z-40 transition duration-400 ease-in-out ">
          <Link to="/home" className="block px-4 py-2 hover:bg-yellow-600">Home</Link>
          <Link to="/movies" className="block px-4 py-2 hover:bg-yellow-600">Movies</Link>
          <Link to="/tv" className="block px-4 py-2 hover:bg-yellow-600">TV Shows</Link>
          <Link to="/anime" className="block px-4 py-2 hover:bg-yellow-600">Anime</Link>
        </div>
      )}

      <form onSubmit={handleSearchSubmit} className="relative  order-last sm:order-none max-w-lg w-full mt-2 sm:mt-0 mx-4 focus-within:ring-2 focus-within:ring-gold-500 focus-within:ring-opacity-50 rounded-full sm:max-w-lg">
        <div className="flex items-center bg-zinc-800 text-black rounded-full px-4 py-2 shadow-lg focus-within:shadow-md transition-shadow duration-600 ease-in-out">
          <button className="mr-2 text-gold-600">
            <FaSearch />
          </button>
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search for movies, TV shows, anime..."
            className="bg-transparent text-gold-500 placeholder-gray-500 focus:outline-none w-full"
          />
        </div>

        {suggestions.length > 0 && (
          <div className="absolute bg-zinc-800 text-gold-300 w-full mt-2 rounded-lg shadow-xl z-10 p-2 max-h-[auto]">
            {suggestions.map((suggestion) => (
              <div
                key={suggestion.id}
                className="flex items-center p-3 hover:bg-gold-500 hover:text-zinc-900 cursor-pointer transition-all duration-500 ease-in-out"
                onClick={() => handleSuggestionClick(suggestion.id, suggestion.media_type)}
              >
                {suggestion.poster_path && (
                  <img
                    src={`https://image.tmdb.org/t/p/w92${suggestion.poster_path}`}
                    alt={suggestion.title || suggestion.name}
                    className="w-10 h-14 mr-3 rounded-md"
                  />
                )}
                <div className="flex flex-col text-xs">
                  <span className="font-semibold">{suggestion.title || suggestion.name}</span>
                  <div className="flex items-center space-x-2 text-[10px] text-gray-400">
                    <span>{suggestion.media_type === 'movie' ? 'Movie' : 'TV Show'}</span>
                    <span>•</span>
                    <span>{suggestion.release_date ? suggestion.release_date.split('-')[0] : suggestion.first_air_date ? suggestion.first_air_date.split('-')[0] : 'N/A' }</span>
                    <span>•</span>
                    <span className="flex items-center text-yellow-400">
                      <FaStar className="mr-1" /> {suggestion.vote_average || 'N/A'}
                    </span>
                  </div>
                </div>
              </div>
            ))}
            <button
              onClick={handleViewMore}
              className="w-full mt-1 text-center text-black bg-gold-500 py-1 rounded-lg font-semibold hover:bg-gold-600 transition duration-500"
            >
              View More
            </button>
          </div>
        )}
      </form>

      <Link to="/login" className=" hidden sm:block border border-gold-600 text-gold-500 rounded-full px-4 py-1 hover:bg-gold-600 hover:text-black transition duration-500">
        Login →
      </Link>
    </nav>
  );
};

export default NavBar;
