import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  FaUser , 
  FaCog, 
  FaSignOutAlt, 
  FaChartBar, 
  FaClock, 
  FaBell 
} from 'react-icons/fa';
import { MdFavorite } from 'react-icons/md';
import NavBar from '../components/NavBar';
import { getFavorites, removeFromFavorites } from '../services/favorites'; // Import your utility functions
import Modal from '../services/Modal'; // Import the modal component for displaying recovery code

const Profile = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [activeSection, setActiveSection] = useState('overview');
  const [favorites, setFavorites] = useState([]); // State for favorites
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const navigate = useNavigate();

  useEffect(() => {
    const storedUserInfo = localStorage.getItem('userInfo');
    
    if (storedUserInfo) {
      try {
        const parsedUserInfo = JSON.parse(storedUserInfo);
        setUserInfo(parsedUserInfo);
        setIsModalOpen(true); // Open modal to show recovery code when user info is loaded
      } catch (error) {
        console.error('Error parsing user info:', error);
        navigate('/login');
      }
    } else {
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    const fetchFavorites = () => {
      const favs = getFavorites();
      setFavorites(favs);
    };

    fetchFavorites();
  }, []);

  const handleLogout = () => {
  localStorage.removeItem('userInfo');
  sessionStorage.removeItem('userInfo');
  navigate('/login'); // Redirect to login page
};

  const handleRemoveFavorite = (id) => {
    removeFromFavorites(id); // Remove from local storage
    const updatedFavorites = favorites.filter(item => item.id !== id);
    setFavorites(updatedFavorites); // Update state to reflect changes
  };

  const handleFavoriteClick = (item) => {
    // Determine media type based on the item's properties
    const mediaType = item.media_type || (item.title ? 'movie' : 'tv'); // Default to 'tv' if media_type is not present
    navigate(`/watch/${mediaType}/${item.id}`);
  };

  const StatCard = ({ icon, title, value }) => (
    <div className="bg-gray-800 p-4 rounded-lg flex items-center space-x-4 hover:bg-gray-700 transition">
      <div className="text-gold text-2xl">{icon}</div>
      <div>
        <p className="text-gray-400 text-sm">{title}</p>
        <p className="text-gold font-bold">{value}</p>
      </div>
    </div>
  );

  const renderSection = React.useCallback(() => {
    if (!userInfo) return null;

    switch (activeSection) {
      case 'overview':
        return (
          <div className="grid md:grid-cols-3 gap-6">
            <StatCard 
              icon={<MdFavorite />} 
              title="Favorites" 
              value={favorites.length} // Display the number of favorites
            />
            <StatCard 
              icon={<FaClock />} 
              title="History" 
              value="N/A" 
            />
            <StatCard 
              icon={<FaBell />} 
              title="Recovery Code" 
              value={userInfo.recoveryCode || 'N/A'} // Display the recovery code
            />
          </div>
        );

      case 'settings':
        return (
          <div className="bg-gray-800 p-6 rounded-lg">
            <h2 className="text-2xl text-gold mb-4">Account Settings</h2>
            <div className="space-y-4">
              <div>
                <label className="block text-gold mb-2">Email</label>
                <input 
                  type="email" 
                  className="w-full p-2 bg-black text-white rounded"
                  value={userInfo.email || ''}
                  readOnly
                />
              </div>
              <div>
                <label className="block text-gold mb-2">Username</label>
                <input 
                  type="text" 
                  className="w-full p-2 bg-black text-white rounded"
                  value={userInfo.username || ''}
                  readOnly
                />
              </div>
              <button className="bg-g old text-black px-4 py-2 rounded hover:bg-yellow-500">
                Save Changes
              </button>
            </div>
          </div>
        );

      case 'favorites':
        return (
          <div className="bg-gray-800 p-6 rounded-lg">
            <h2 className="text-2xl text-gold mb-4">Your Favorites</h2>
            <div>
              {favorites.length === 0 ? (
                <p className="text-gray-400">No favorites added yet.</p>
              ) : (
                favorites.map(item => (
                  <div key={item.id} className="flex justify-between items-center bg-gray-700 p-4 rounded mb-2">
                    <div className="flex items-center cursor-pointer" onClick={() => handleFavoriteClick(item)}>
                      <img 
                        src={item.poster || `https://image.tmdb.org/t/p/w500${item?.poster_path}` || 'default-poster.png'} 
                        alt={item.title || item.name} 
                        className="w-16 h-24 rounded mr-4"
                      />
                      <h3 className="text-gold">{item.title || item.name}</h3>
                    </div>
                    <button 
                      onClick={() => handleRemoveFavorite(item.id)} 
                      className="text-red-500 hover:text-red-400"
                    >
                      Remove
                    </button>
                  </div>
                ))
              )}
            </div>
          </div>
        );

      default:
        return null;
    }
  }, [activeSection, userInfo, favorites]);

  if (!userInfo) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-black">
        <div className="text-gold text-2xl">Loading...</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black text-white p-8">
      <section className='mt-8 '>
        <NavBar />
      </section>
      <div className="max-w-6xl mx-auto bg-gray-900 rounded-lg shadow-2xl overflow-hidden">
        <div className="bg-gradient-to-r from-black to-gray-800 text-gold p-6 rounded-t-lg">
          <div className="flex items-center space-x-6">
            <div className="w-24 h-24 rounded-full border-4 border-gold overflow-hidden">
              <img 
                src={userInfo.profilePicture || 'default-avatar.png'}
                alt="Profile" 
                className="w-full h-full object-cover"
              />
            </div>
            <div>
              <h1 className="text-3xl font-bold text-gold">{userInfo.username}</h1>
              <p className="text-gray-300">{userInfo.email}</p>
              <p className="text-gray-300">Recovery Code: <span className="text-gold">{userInfo.recoveryCode || 'N/A'}</span></p>
            </div>
          </div>
        </div>

        <div className="bg-gray-800 p-4">
          <nav className="flex space-x-6 items-center">
            <button 
              onClick={() => setActiveSection('overview')}
              className={`flex items-center space-x-2 ${
                activeSection === 'overview' 
                  ? 'text-gold' 
                  : 'text-gray-400 hover:text-gold'
              }`}
            >
              <FaUser  />
              <span>Overview</span>
            </button>
            <button 
              onClick={() => setActiveSection('favorites')}
              className={`flex items-center space-x-2 ${
                activeSection === 'favorites' 
                  ? 'text-gold' 
                  : 'text-gray-400 hover:text-gold'
              }`}
            >
              <MdFavorite />
              <span>Favorites</span>
            </button>
            <button 
              onClick={() => setActiveSection('settings')}
              className={`flex items-center space-x-2 ${
                activeSection === 'settings' 
                  ? 'text-gold' 
                  : 'text-gray-400 hover:text-gold'
              }`}
            >
              <FaCog />
              <span>Settings</span>
            </button>
            <button 
              onClick={handleLogout}
              className="ml-auto flex items-center space-x-2 text-red-500 hover:text-red-400"
            >
              <FaSignOutAlt />
              <span>Logout</span>
            </button>
          </nav>
        </div>

        <div className="p-6">
          {renderSection()}
        </div>
      </div>

      {/* Modal for Recovery Code */}
      <Modal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
        recoveryCode={userInfo.recoveryCode} 
      />
    </div>
  );
};

export default Profile;