// PrivacyPolicy.jsx
import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="bg-gradient-to-b from-gray-900 to-black text-white min-h-screen p-6">
      <h1 className="text-center text-4xl font-bold mb-8">Privacy Policy</h1>
      
      <p className="mb-6">
        At <span className="font-bold text-gold-500">Streamify</span>, your privacy is important to us. This Privacy Policy outlines how we collect, use, and protect your information when you use our services.
      </p>

      <h2 className="text-2xl font-semibold mb-4">1. Information We Collect</h2>
      <p className="mb-6">
 We may collect personal information from you when you register on our site, place an order, subscribe to our newsletter, or interact with us in other ways. The types of information we may collect include:
      </p>
      <ul className="list-disc list-inside mb-6">
        <li className="mb-2">Name</li>
        <li className="mb-2">Email address</li>
        <li className="mb-2">Phone number</li>
        <li className="mb-2">Payment information</li>
        <li className="mb-2">Usage data</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4">2. How We Use Your Information</h2>
      <p className="mb-6">
        We use the information we collect for various purposes, including:
      </p>
      <ul className="list-disc list-inside mb-6">
        <li className="mb-2">To provide and maintain our service</li>
        <li className="mb-2">To notify you about changes to our service</li>
        <li className="mb-2">To allow you to participate in interactive features of our service when you choose to do so</li>
        <li className="mb-2">To provide customer support</li>
        <li className="mb-2">To gather analysis or valuable information so that we can improve our service</li>
        <li className="mb-2">To monitor the usage of our service</li>
        <li className="mb-2">To detect, prevent, and address technical issues</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4">3. Data Security</h2>
      <p className="mb-6">
        The security of your data is important to us, but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.
      </p>

      <h2 className="text-2xl font-semibold mb-4">4. Your Rights</h2>
      <p className="mb-6">
        Depending on your location, you may have certain rights regarding your personal information, including the right to access, correct, or delete your personal data. If you wish to exercise these rights, please contact us using the information provided below.
      </p>

      <h2 className="text-2xl font-semibold mb-4">5. Contact Us</h2>
      <p className="mb-6">
        If you have any questions about this Privacy Policy, please contact us:
      </p>
      <p className="mb-2">
        Email: <span className="font-bold text-gold-500">support@streamify.com</span>
      </p>
      <p className="mb-6">
        Address: [Your Company Address Here]
      </p>

      <p className="text-center">
        Thank you for trusting <span className="font-bold text-gold-500">Streamify</span> with your information.
      </p>
    </div>
  );
};

export default PrivacyPolicy;