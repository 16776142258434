// TermsAndServices.jsx
import React from 'react';

const TermsAndServices = () => {
  return (
    <div className="bg-gradient-to-b from-gray-900 to-black text-white min-h-screen p-6">
      <h1 className="text-center text-4xl font-bold mb-8">Terms and Services</h1>
      
      <p className="mb-6">
        Welcome to <span className="font-bold text-gold-500">Streamify</span>. By accessing or using our services, you agree to comply with and be bound by the following terms and conditions. Please read these terms carefully before using our services.
      </p>

      <h2 className="text-2xl font-semibold mb-4">1. Acceptance of Terms</h2>
      <p className="mb-6">
        By using <span className="font-bold text-gold-500">Streamify</span>, you agree to these Terms and Services and our Privacy Policy. If you do not agree to these terms, you may not use our services.
      </p>

      <h2 className="text-2xl font-semibold mb-4">2. Changes to Terms</h2>
      <p className="mb-6">
        We reserve the right to modify these terms at any time. Changes will be effective immediately upon posting on our website. Your continued use of the services after changes are made constitutes your acceptance of the new terms.
      </p>

      <h2 className="text-2xl font-semibold mb-4">3. User Accounts</h2>
      <p className="mb-6">
        To access certain features of <span className="font-bold text-gold-500">Streamify</span>, you may be required to create an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
      </p>

      <h2 className="text-2xl font-semibold mb-4">4. Subscription and Billing</h2>
      <p className="mb-6">
        By subscribing to <span className="font-bold text-gold-500">Streamify</span>, you agree to pay the subscription fees associated with your chosen plan. Fees are billed in advance on a recurring basis. You can cancel your subscription at any time through your account settings.
      </p>

      <h2 className="text-2xl font-semibold mb-4">5. Content Usage</h2>
      <p className="mb-6">
        All content provided on <span className="font-bold text-gold-500">Streamify</span> is for personal, non-commercial use only. You may not distribute, modify, or publicly display any content without our prior written consent.
      </p>

      <h2 className="text-2xl font-semibold mb-4">6. Termination</h2>
      <p className="mb-6">
        We reserve the right to suspend or terminate your account and access to our services at any time, without notice, for conduct that we believe violates these Terms and Services or is harmful to other users of <span className="font-bold text-gold-500">Streamify</span>.
      </p>

      <h2 className="text-2xl font-semibold mb-4">7. Limitation of Liability</h2>
      <p className="mb-6">
        To the fullest extent permitted by law, <span className="font-bold text-gold-500">Streamify</span> shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or relating to your use of our services.
      </p>

      <h2 className="text-2xl font-semibold mb-4">8. Governing Law</h2>
      <p className="mb-6">
        These Terms and Services shall be governed by and construed in accordance with the laws of [Your Jurisdiction]. Any disputes arising out of or relating to these terms shall be resolved in the courts of [Your Jurisdiction].
      </p>

      <h2 className="text-2xl font-semibold mb-4">9. Contact Us</h2>
      <p className="mb-6">
        If you have any questions about these Terms and Services, please contact us at <span className="font-bold text-gold-500">support@streamify.com</span>.
      </p>

      <p className="text-center mt-8">
        Thank you for choosing <span className="font-bold text-gold-500">Streamify</span>. We hope you enjoy our services!
      </p>
    </div>

  );
};

export default TermsAndServices;