import React from 'react';
import { useNavigate } from 'react-router-dom'; // Updated import to useNavigate

const RecommendedSidebar = ({ content }) => {
  const navigate = useNavigate(); // Use useNavigate for routing

  // Limit the content to 5 items
  const limitedContent = content.slice(0, 5);

  // Handle redirect to WatchPage
  const handleItemClick = (id, mediaType) => {
    navigate(`/watch/${mediaType}/${id}`); // Redirect to the WatchPage
  };

  return (
    <div className="bg-zinc-950 text-gold-600 rounded-lg p-4 shadow-lg">
      <h3 className="text-2xl font-semibold mb-4 ">Featured</h3>
      <div className="space-y-4">
        {limitedContent.map((item) => (
          <div
            key={item.id}
            className="flex items-center rounded-xl space-x-4 cursor-pointer hover:bg-black hover:scale-105 transition-all duration-800"
            onClick={() => handleItemClick(item.id, item.media_type)} // Redirect to WatchPage on click
          >
            <img
              src={`https://image.tmdb.org/t/p/w92${item.poster_path}`} 
              alt={item.title || item.name}
              className="w-16 h-auto rounded-md"
            />
            <div className="text-sm">
              <h4 className="font-medium text-white hover:text-gold-500">
                {item.title || item.name}
              </h4>
              <p className="text-xs text-gray-400">{item.release_date || item.first_air_date}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecommendedSidebar;
