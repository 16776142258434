import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-black text-gold-400 py-8 rounded-lg">
      <div className="container mx-auto px-4 max-w-6xl">
        <div className="flex flex-col md:flex-row md:justify-between items-center">
          {/* Logo */}
          <div className="text-2xl font-bold text-gold-500 mb-4 md:mb-0">Streamify</div>

          {/* Navigation */}
          <div className="flex flex-wrap justify-center space-x-4 mb-4 md:mb-0">
            <a href="/about" className="text-gold-400 hover:text-gold-600 transition">About Us</a>
            <a href="/terms" className="text-gold-400 hover:text-gold-600 transition">Terms of Service</a>
            <a href="/privacy" className="text-gold-400 hover:text-gold-600 transition">Privacy Policy</a>
            <a href="/dcma" className="text-gold-400 hover:text-gold-600 transition">DMCA</a>
            <a href="/contact" className="text-gold-400 hover:text-gold-600 transition">Contact Us</a>
          </div>

          {/* Social Icons */}
          <div className="flex flex-wrap justify-center space-x-4">
            <a href="#twitter" className="text-gold-500 hover:text-gold-600">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="#reddit" className="text-gold-500 hover:text-gold-600">
              <i className="fab fa-reddit"></i>
            </a>
            <a href="#instagram" className="text-gold-500 hover:text-gold-600">
              <i className="fab fa-instagram"></i>
            </a>
          </div>
        </div>

        {/* Copyright */}
        <div className="text-center text-xs text-gold-400 opacity-60 mt-6">
          © 2023 Streamify. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;