import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import IndexPage from './pages/IndexPage';
import HomePage from './pages/HomePage';
import { ParallaxProvider } from 'react-scroll-parallax';
import SearchPage from './pages/SearchPage';
import WatchPage from './pages/WatchPage'; // Assuming this is the correct page for individual content viewing
import './index.css';
import Login from '../src/pages/Login';
import Profile from '../src/pages/Profile';
import Movies from './pages/Movies';
import TVShows from './pages/TvShows';
import Anime from './pages/Anime';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import TermsAndServices from './pages/Terms';
import DMCA from './pages/DCMA';
import PrivacyPolicy from './pages/Privacy';
import RecoverPassword from './services/RecoverPassword'

// Create a PrivateRoute component to handle redirection
const PrivateRoute = ({ element }) => {
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem('userInfo')) || JSON.parse(sessionStorage.getItem('userInfo'));

  useEffect(() => {
    if (!userInfo) {
      navigate('/login'); // Redirect to login if not logged in
    }
  }, [userInfo, navigate]);

  return userInfo ? element : null; // Return the element if user is logged in
};

const App = () => {
  return (
    <ParallaxProvider>
      <Router>
        <Routes>
          <Route path="/" element={<IndexPage />} />  {/* Index Page */}
          <Route path="/home" element={<HomePage />} />  {/* Home Page */}
          <Route path="/search" element={<SearchPage />} />  {/* Search Page */}
          <Route path="/watch/:mediaType/:id" element={<WatchPage />} />  {/* Watch Page with mediaType and ID */}
          <Route path="/login" element={<Login />} />  {/* Login Page */}
          <Route path="/profile" element={<PrivateRoute element={<Profile />} />} />  {/* Profile Page */}
          <Route path="/movies" element={<Movies />} />  {/* Movies Page */}
          <Route path="/tv" element={<TVShows />} />  {/* TV Shows Page */}
          <Route path="/anime" element={<Anime />} />  {/* Anime Page */}
          <Route path="/about" element={<AboutUs />} />  {/* About Us Page */}
          <Route path="/contact" element={<ContactUs />} />  {/* Contact Us Page */}
          <Route path="/terms" element={<TermsAndServices />} />  {/* Terms and Services Page */}
          <Route path="/dcma" element={<DMCA />} />  {/* DMCA Page */}
          <Route path="/privacy" element={<PrivacyPolicy />} />  {/* Privacy Policy Page */}
          <Route path="/recover-password" element={<RecoverPassword />} />  {/* Recover Password Page */}

        </Routes>
      </Router>
      
    </ParallaxProvider>
  );
};

export default App;
