import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaSearch, FaStar,  FaTv, FaFilm , FaShieldAlt} from 'react-icons/fa';
import { getSearchSuggestions, searchContent } from '../services/search';
import { getFeaturedMovies, getFeaturedTVShows } from '../services/api';
import Footer from '../components/Footer';

const IndexPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [featuredContent, setFeaturedContent] = useState([]);
  const navigate = useNavigate();

  // Fetch featured content
  useEffect(() => {
    const fetchFeaturedContent = async () => {
      try {
        const movies = await getFeaturedMovies();
        const tvShows = await getFeaturedTVShows();
        const combinedContent = [...movies, ...tvShows]
          .sort(() => 0.5 - Math.random())
          .slice(0, 21)
          .map(item => ({
            ...item,
            media_type: item.title ? 'movie' : 'tv'
          }));
        setFeaturedContent(combinedContent);
      } catch (error) {
        console.error('Error fetching featured content', error);
      }
    };
    fetchFeaturedContent();
  }, []);

  // Search suggestions
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fetchSuggestions = async () => {
        if (searchQuery.length > 2) {
          try {
            const results = await getSearchSuggestions(searchQuery);
            setSuggestions(results.slice(0, 4));
          } catch (error) {
            console.error('Search suggestions error:', error);
          }
        } else {
          setSuggestions([]);
        }
      };
      fetchSuggestions();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery]);

  // Search handling
  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      try {
        const results = await searchContent(searchQuery);
        navigate(`/search?query=${searchQuery}`, { 
          state: { 
            query: searchQuery, 
            results 
          } 
        });
        setSuggestions([]);
      } catch (error) {
        console.error('Search error', error);
      }
    }
  };

  // Suggestion click handler
  const handleSuggestionClick = (id, mediaType) => {
    navigate(`/watch/${mediaType}/${id}`);
    setSearchQuery('');
    setSuggestions([]);
  };

  // View More handler
  const handleViewMore = () => {
    navigate(`/search?query=${searchQuery}`);
    setSuggestions([]);
  };

  return (
    <div className=" text-white min-h-screen bg-gradient-to-b from-gray-900 to-black">
      {/* Navigation Bar */}
      <nav className="fixed top-0 left-0 w-full bg-black text-gold-300 p-4 flex flex-wrap justify-between items-center shadow-md z-50 sm:flex-nowrap sm:space-x-4">
        <div className="flex items-center justify-between  sm:w-auto  w-full">
          {/* Hamburger Menu Button */}
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="text-gold-500 p-2 rounded-md focus:outline-none transition duration-500 transform flex flex-col justify-center items-center space-y-1 hover:text-gold-600"
          >
            <div
              className={`w-7 h-0.5 bg-gold-500 transition-all duration-500 transform ${isOpen ? 'rotate-45 translate-y-2' : ''}`}
            ></div>
            <div
              className={`w-7 h-0.5 bg-gold-500 transition-all duration-500 transform ${isOpen ? 'opacity-0' : ''}`}
            ></div>
            <div
              className={`w-7 h-0.5 bg-gold-500 transition-all duration-500 transform ${isOpen ? '-rotate-45 -translate-y-2' : ''}`}
            ></div>
          </button>
          <Link to="/" className="text-gold-500 font-bold text-2xl hover:text-gold-600 duration-400">
            Streamify
          </Link>

          {/* Login Button for Mobile */}
        <Link 
          to="/login" 
          className="sm:hidden border border-gold-600 text-gold-500 rounded-full px-4 py-1 
            hover:bg-gold-600 hover:text-black transition duration-500 "
        >
          Login →
        </Link>
        </div>

        {isOpen && (
          <div className="absolute top-full mt-2 left-4 bg-yellow-500 text-black rounded-lg shadow-md w-40 py-2 z-40 transition duration-400 ease-in-out">
            <Link to="/home" className="block px-4 py-2 hover:bg-yellow-600">Home</Link>
            <Link to="/movies" className="block px-4 py-2 hover:bg-yellow-600">Movies</Link>
            <Link to="/tv-shows" className="block px-4 py-2 hover:bg-yellow-600">TV Shows</Link>
            <Link to="/anime" className="block px-4 py-2 hover:bg-yellow-600">Anime</Link>
          </div>
        )}

        <Link to="/login" className="hidden sm:block border border-gold-600 text-gold-500 rounded-full px-4 py-1 hover:bg-gold-600 hover:text-black transition duration-500">
          Login →
        </Link>
      </nav>

      {/* Hero Section with Search */}
      <div className="relative pt-20 px-4 md:px-8 lg:px-16 min-h-screen flex flex-col justify-center items-center">
        <div className="max-w-4xl w-full text-center">
          <h1 className="text-4xl md:text-5xl font-bold text-gold-500 mb-6">
            Discover Your Next Favorite Show
          </h1>
          <p className="text-xl text-gray-300 mb-8">
            Stream the latest movies, TV shows, and anime
          </p>

          {/* Search Form */}
          <div className="relative w-full max-w-xl mx-auto">
            <form onSubmit={handleSearchSubmit} className="relative w-full">
              <div className="flex items-center bg-zinc-800 text-black rounded-full px-4 py-2 shadow-lg focus-within:shadow-md transition-shadow duration-600 ease-in-out">
                <button className="mr-2 text-gold-600">
                  <FaSearch />
                </button>
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search for movies, TV shows, anime..."
                  className="bg-transparent text-gold-500 placeholder-gray-500 focus:outline-none w-full"
                />
              </div>

              {/* Suggestions Dropdown */}
              {suggestions.length > 0 && (
                <div className="absolute bg-zinc-800 text-gold-300 w-full mt-2 rounded-xl shadow-xl z-50 p-2 max-h-[auto]">
                  {suggestions.map((suggestion) => (
                    <div
                      key={suggestion.id}
                      className="flex items-center p-3 hover:bg-gold-500 hover:text-zinc-900 cursor-pointer transition-all duration-500 ease-in-out rounded-lg"
                      onClick={() => handleSuggestionClick(suggestion.id, suggestion.media_type)}
                    >
                      {suggestion.poster_path && (
                        <img
                          src={`https://image.tmdb.org/t/p/w92${suggestion.poster_path}`}
                          alt={suggestion.title || suggestion.name}
                          className="w-10 h-14 mr-3 rounded-md"
                        />
                      )}
                      <div className="flex flex-col text-xs">
                        <span className="font-semibold place-self-start">{suggestion.title || suggestion.name}</span>
                        <div className="flex items-center space-x-2 text-[10px] text-gray-400">
                          <span>{suggestion.media_type === 'movie' ? 'Movie' : 'TV Show'}</span>
                          <span>•</span>
                          <span>{suggestion.release_date ? suggestion.release_date.split('-')[0] : suggestion.first_air_date ? suggestion.first_air_date.split('-')[0] : 'N/A'}</span>
                          <span>•</span>
                          <span className="flex items-center text-yellow-400">
                            <FaStar className="mr-1" /> {suggestion.vote_average || 'N/A'}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                  <button
                    onClick={handleViewMore}
                    className="w-full mt-1 text-center text-black bg-gold-500 py-1 rounded-lg font-semibold hover:bg-gold-600 transition-colors duration-300"
                  >
                    View More
                  </button>
                </div>
              )}
            </form>
          </div>
          {/* Website Description Section */}
<div className="text-center px-4 md:px-8 lg:px-16 my-10 max-w-4xl mx-auto">
  <h2 className="text-3xl font-bold text-gold-500 mb-6">
    Your Ultimate Streaming Companion
  </h2>
  
  <div className="bg-black/50 rounded-xl p-8 shadow-xl border border-gold-500/20">
    <p className="text-gold-300 text-lg mb-6 leading-relaxed">
      Streamify is not just another streaming platform – it's your personalized entertainment hub designed to revolutionize how you discover and enjoy content.
    </p>

    <div className="grid md:grid-cols-3 gap-6">
      <div className="bg-zinc-800 p-5 rounded-lg hover:bg-zinc-700 transition duration-300">
        <FaSearch className="text-gold-500 text-4xl mb-4 mx-auto" />
        <h3 className="text-xl font-semibold text-gold-400 mb-2">Comprehensive Search</h3>
        <p className="text-gold-200 text-sm">
          Advanced search across movies, TV shows, and anime with intelligent suggestions and filters.
        </p>
      </div>

      <div className="bg-zinc-800 p-5 rounded-lg hover:bg-zinc-700 transition duration-300">
        <FaStar className="text-gold-500 text-4xl mb-4 mx-auto" />
        <h3 className="text-xl font-semibold text-gold-400 mb-2">Curated Recommendations</h3>
        <p className="text-gold-200 text-sm">
          Personalized content suggestions based on your viewing history and preferences.
        </p>
      </div>

      <div className="bg-zinc-800 p-5 rounded-lg hover:bg-zinc-700 transition duration-300">
        <FaShieldAlt className="text-gold-500 text-4xl mb-4 mx-auto" />
        <h3 className="text-xl font-semibold text-gold-400 mb-2">Safe & Ad-Free</h3>
        <p className="text-gold-200 text-sm">
          Enjoy uninterrupted streaming with multiple server options and content filtering.
        </p>
      </div>
    </div>

    <div className="mt-8 border-t border-gold-500/20 pt-6">
      <p className="text-gold-300 italic">
        "Discover, Stream, Enjoy – Streamify brings the world of entertainment to your fingertips."
      </p>
    </div>
  </div>
</div>

          {/* Start Watching Button */}
          <div className="mt-6">
            <Link to="/home" className="bg-gold-500 text-black py-2 px-4 rounded-full hover:bg-gold-600 transition-colors duration-300">
              Start Watching
            </Link>
          </div>
        </div>
      </div>

 {/* Suggestions Area */}
<div className="mt-10 px-4 md:px-8 lg:px-16">
  <h2 className="text-3xl font-bold text-gold-500 mb-8 text-center">
    Featured
  </h2>
  <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-7 gap-4">
    {featuredContent.map(item => {
      // Determine media type based on the item's properties
      const mediaType = item.title ? 'movie' : 'tv';

      return (
        <div 
          key={item.id} 
          className="relative overflow-hidden rounded-lg shadow-lg transition-transform duration-300 transform hover:scale-105 cursor-pointer"
          onClick={() => navigate(`/watch/${mediaType}/${item.id}`)}
        >
          <div className="relative pt-[140%] w-full">
            <img 
              src={`https://image.tmdb.org/t/p/w500${item.poster_path}`} 
              alt={item.title || item.name} 
              className="absolute inset-0 w-full h-full object-cover rounded-lg"
            />
            {/* Add media type icon */}
            <div className="absolute top-2 left-2 bg-black/60 rounded-full px-2 py-2 items-center">
                {mediaType === 'movie' ? (
                  <FaFilm className="text-gold-400 text-sm " />
                ) : (
                  <FaTv className="text-gold-400 text-sm" />
                )}
              </div>
            <div className="absolute top-2 right-2 bg-black/60 rounded-full px-2 py-1 flex items-center">
              <FaStar className="text-gold-500 mr-1 text-xs" />
              <span className="text-xs text-gold-400 font-bold">
                {item.vote_average ? item.vote_average.toFixed(1) : 'N/A'}
              </span>
            </div>
            <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/80 to-transparent p-2">
            <h3 className="text-xs font-semibold text-gold-400 p-2 bg-black bg-opacity-50 rounded-full truncate hover:bg-gold-600 hover:text-black transition duration-600">
            {item.title || item.name}
              </h3>
              
            </div>
          </div>
        </div>
      );
    })}
  </div>
</div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default IndexPage;