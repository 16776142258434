// src/pages/SearchPage.jsx
import React, { useState, useEffect } from 'react';
import {Link, useNavigate, useLocation } from 'react-router-dom';
import { searchContent } from '../services/search';
import { FaPlay, FaStar } from 'react-icons/fa';
import NavBar from '../components/NavBar';
import Filter from '../components/Filter';
import Footer from '../components/Footer';
const SearchPage = () => {
  const [results, setResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [filters, setFilters] = useState({ genre: '', year: '', type: '' });
  const navigate = useNavigate();
  const location = useLocation();
  const searchQuery = new URLSearchParams(location.search).get('query');

  useEffect(() => {
    const fetchResults = async () => {
      const data = await searchContent(searchQuery);
      const combinedResults = [
        ...data.movies,
        ...data.tvShows,
        ...data.anime
      ];
      setResults(combinedResults);
      setFilteredResults(combinedResults);
    };
    fetchResults();
  }, [searchQuery]);

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    const filtered = results.filter((item) => {
      const matchesGenre = newFilters.genre ? item.genre_ids?.includes(Number(newFilters.genre)) : true;
      const matchesYear = newFilters.year ? (item.release_date || item.first_air_date || '').startsWith(newFilters.year) : true;
      const matchesType = newFilters.type ? item.media_type === newFilters.type : true;
      return matchesGenre && matchesYear && matchesType;
    });
    setFilteredResults(filtered);
  };

  return (
    <div className="bg-gradient-to-b from-gray-900 to-black min-h-screen p-6">
      <NavBar />
      <Filter onFilterChange={handleFilterChange} />
      <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-6 gap-4">
        {filteredResults.length > 0 ? (
          filteredResults.map(item => (
            <Link 
              to={`/watch/${item.media_type || 'movie'}/${item.id}`} 
              key={item.id} 
              className="group relative"
            >
              <div className="
                relative overflow-hidden rounded-xl shadow-xl 
                transition-all duration-300 
                border border-transparent
                group-hover:border-gold-500/50 
                group-hover:scale-105 
                group-hover:shadow-2xl 
                bg-black/5"
              >
                {/* Poster Image */}
                <div className="relative pt-[150%] overflow-hidden"> 
                  <img 
                    src={`https://image.tmdb.org/t/p/w500${item.poster_path}`} 
                    alt={item.title || item.name}
                    className="absolute inset-0 w-full h-full object-cover 
                               opacity-90 group-hover:opacity-100 
                               transition-opacity duration-300 
                               transform group-hover:scale-110"
                  />
                  
                  {/* Hover Overlay */}
                  <div className="absolute inset-0 bg-black/0 group-hover:bg-black/30 
                                  transition-all duration-300 flex items-center justify-center">
                    <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      <FaPlay className="text-gold-500 text-4xl 
                                        transform hover:scale-110 
                                        transition-transform" />
                    </div>
                  </div>
                  
                  {/* Rating Overlay */}
                  <div className="absolute top-2 right-2 
                                  bg-black/60 rounded-full 
                                  px-2 py-1 flex items-center">
                    <FaStar className="text-gold-500 mr-1" />
                    <span className="text-xs text-gold-400 font-bold">
                      {item.vote_average ? item.vote_average.toFixed(1) : 'N/A'}
                    </span>
                  </div>
                </div>

                {/* Title Overlay */}
                <div className="absolute bottom-0 left-0 right-0 
                                bg-gradient-to-t from-black/80 to-transparent 
                                p-2">
                  <h3 className="text-xs font-semibold text-gold-400 truncate">
                    {item.title || item.name}
                  </h3>
                </div>
              </div>
            </Link>
          ))
        ) : (
          <p class Name="text-center text-gray-400">No results found.</p>
        )}
      </div>
      {/* Footer section */}
      <section className='mt-12 w-full rounded-lg'>
        <Footer className="rounded-lg" />
      </section>
      
    </div>
    

  );
};



export default SearchPage;