import React from 'react';

const Modal = ({ isOpen, onClose, recoveryCode }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-gold-500">This is your Password Recovery Code</h2>
        <p className="mt-4 text-gold-300">
          This code is necessary for you to reset your password because this website doesn't store any information about your password. 
          Please save it securely!
        </p>
        <p className="mt-4 text-gold-400 font-semibold">Recovery Code: <span className="text-gold-500">{recoveryCode}</span></p>
        <div className="mt-6">
          <button 
            onClick={onClose} 
            className="bg-gold-500 text-black px-4 py-2 rounded hover:bg-gold-400 transition duration-300"
          >
            Saved it
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;