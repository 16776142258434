// DMCA.jsx
import React from 'react';

const DMCA = () => {
  return (
    <div className="bg-gradient-to-b from-gray-900 to-black text-white min-h-screen p-6">
      <h1 className="text-center text-4xl font-bold mb-8">DMCA Policy</h1>
      
      <p className="mb-6">
        At <span className="font-bold text-gold-500">Streamify</span>, we respect the intellectual property rights of others and expect our users to do the same. This DMCA policy outlines our procedure for responding to claims of copyright infringement.
      </p>

      <h2 className="text-2xl font-semibold mb-4">1. Notice of Infringement</h2>
      <p className="mb-6">
        If you believe that your work has been copied in a way that constitutes copyright infringement, please provide us with a written notice that includes:
      </p>
      <ul className="list-disc list-inside mb-6">
        <li className="mb-2">A physical or electronic signature of the copyright owner or a person authorized to act on behalf of the owner.</li>
        <li className="mb-2">Identification of the copyrighted work claimed to have been infringed.</li>
        <li className="mb-2">Identification of the material that is claimed to be infringing and where it is located on our site.</li>
        <li className="mb-2">Your contact information, including your address, telephone number, and email address.</li>
        <li className="mb-2">A statement that you have a good faith belief that the use of the material is not authorized by the copyright owner, its agent, or the law.</li>
        <li className="mb-2">A statement that the information in the notification is accurate, under penalty of perjury.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4">2. Counter-Notification</h2>
      <p className="mb-6">
        If you believe that your material has been removed by mistake or misidentification, you may submit a counter-notification. The counter-notification must include:
      </p>
      <ul className="list-disc list-inside mb-6">
        <li className="mb-2">Your physical or electronic signature.</li>
        <li className="mb-2">Identification of the material that has been removed and the location where it appeared before removal.</li>
        <li className="mb-2">Your name, address, telephone number, and email address.</li>
        <li className="mb-2">A statement under penalty of perjury that you have a good faith belief that the material was removed due to a mistake or misidentification.</li>
        <li className="mb-2">Your consent to the jurisdiction of the federal court in your district.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4">3. Contact Information</h2>
      <p className="mb-6">
        Please send your DMCA notices to:
      </p>
      <p className="mb-2">
        Email: <span className="font-bold text-gold-500">support@streamify.com</span>
      </p>
      

      <p className="text-center">
        Thank you for helping us maintain a respectful and lawful environment at <span className="font-bold text-gold-500">Streamify</span>.
      </p>
    </div>
  );
};

export default DMCA;