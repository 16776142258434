import React, { useState, useEffect } from 'react';
import { 
  fetchTopRatedMovies, 
  fetchTopRatedTVShows, 
  fetchTopRatedAnime 
} from '../services/api';
import { Link } from 'react-router-dom';
import { 
  FaFilm, 
  FaTv, 
  FaStar, 
  FaPlay,
  FaDesktop,
  FaDragon 
} from 'react-icons/fa';

const MustWatch = () => {
  const [activeCategory, setActiveCategory] = useState('movies');
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchContent = async () => {
    setLoading(true);
    try {
      let results;
      switch (activeCategory) {
        case 'movies':
          results = await fetchTopRatedMovies(1);
          break;
        case 'tvshows':
          results = await fetchTopRatedTVShows(1);
          break;
        case 'anime':
          results = await fetchTopRatedAnime(1);
          break;
        default:
          results = [];
      }
      
      setContent(
        results
          .filter(item => item.poster_path)
          .slice(0, 18)
      );
    } catch (error) {
      console.error('Error fetching content:', error);
      setContent([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContent();
  }, [activeCategory]);

  // Category icon mapping
  const getCategoryIcon = (category) => {
    const iconMap = {
      'movies': FaFilm,
      'tvshows': FaDesktop,
      'anime': FaDragon
    };
    return iconMap[category] || FaFilm;
  };

  const CategoryToggle = () => {
    const categories = [
      { 
        key: 'movies', 
        label: 'Movies', 
        icon: <FaFilm className="mr-2 " /> 
      },
      { 
        key: 'tvshows', 
        label: 'TV Shows', 
        icon: <FaTv className="mr-2" /> 
      },
      { 
        key: 'anime', 
        label: 'Anime', 
        icon: <FaDragon className="mr-2" /> 
      }
    ];

    return (
      <div className="flex space-x-2">
        {categories.map((category) => (
          <button
            key={category.key}
            onClick={() => setActiveCategory(category.key)}
            className={`
              flex items-center px-4 py-2 rounded-full transition-all duration-300 
              text-sm font-medium tracking-wider
              transform hover:scale-105 hover:shadow-lg 
              ${activeCategory === category.key 
                ? 'bg-gold-500 text-black scale-105 shadow-lg' 
                : 'bg-black/10 text-gold-400 hover:bg-gold-500/20' }
            `}
          >
            {category.icon}
            <div className='hidden md:block'>
            {category.label}
            </div>
          </button>
        ))}
      </div>
    );
  };

  return (
    <div className="w-full px-4 py-8">
      <div className="max-w-screen-2xl mx-auto">
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-3xl font-bold text-gold-500 tracking-wide">
            Must Watch
          </h2>
          <CategoryToggle />
        </div>

        {loading ? (
          <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-6 gap-4">
            {[...Array(18)].map((_, index) => (
              <div 
                key={index} 
                className="bg-black/10 rounded-lg animate-pulse h-64"
              ></div>
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-6 gap-4">
            {content.map(item => {
              // Determine the category icon
              const CategoryIcon = getCategoryIcon(activeCategory);

              // Set the content type for the watch page redirect
              const contentType = activeCategory === 'movies' ? 'movie' : 'tv';

              return (
                <Link 
                  to={`/watch/${contentType}/${item.id}`} 
                  key={item.id} 
                  className="group relative"
                >
                  <div className="
                    relative overflow-hidden rounded-xl shadow-xl 
                    transition-all duration-300 
                    border border-transparent
                    group-hover:border-gold-500/50 
                    group-hover:scale-105 
                    group-hover:shadow-2xl 
                    bg-black/5">
                    {/* Category Icon Overlay */}
                    <div className="absolute top-2 left-2 z-10 
                                    bg-black/60 rounded-full 
                                    p-2 flex items-center justify-center">
                      <CategoryIcon className="text-gold-400 text-xs" />
                    </div>

                    {/* Poster Image */}
                    <div className="relative pt-[150%] overflow-hidden"> 
                      <img 
                        src={`https://image.tmdb.org/t/p/w500${item.poster_path}`} 
                        alt={item.title || item.name}
                        className="absolute inset-0 w-full h-full object-cover 
                                   opacity-90 group-hover:opacity-100 
                                   transition-opacity duration-300 
                                   transform group-hover:scale-110"
                      />
                      
                      {/* Hover Overlay */}
                      <div className="absolute inset-0 bg-black/0 group-hover:bg-black/30 
                                      transition-all duration-300 flex items-center justify-center">
                        <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                          <FaPlay className="text-gold-500 text-4xl 
                                            transform hover:scale-110 
                                            transition-transform" />
                        </div>
                      </div>
                      
                      {/* Rating Overlay */}
                      <div className="absolute top-2 right-2 
                                      bg-black/60 rounded-full 
                                      px-2 py-1 flex items-center">
                        <FaStar className="text-gold-500 mr-1" />
                        <span className="text-xs text-gold-400 font-bold">
                          {item.vote_average ? item.vote_average.toFixed(1) : 'N/A'}
                        </span>
                      </div>
                    </div>

                    {/* Title Overlay */}
                    <div className="absolute bottom-0 left-0 right-0 
                                    bg-gradient-to-t from-black/80 to-transparent 
                                    p-2">
                <h3 className="text-xs font-semibold text-gold-400 p-2 bg-black bg-opacity-50 rounded-full truncate hover:bg-gold-600 hover:text-black transition duration-600">
                {item.title || item.name}
                      </h3>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default MustWatch;