// Anime.jsx
import React, { useState, useEffect } from 'react';
import { fetchAnimeByReleaseDate } from '../services/api';  // Ensure correct path for anime API
import Pagination from '../components/Pagination';  // Pagination component
import { Link } from 'react-router-dom';
import { FaPlay, FaStar } from 'react-icons/fa';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

const Anime = () => {
  const [anime, setAnime] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const loadAnime = async () => {
      const data = await fetchAnimeByReleaseDate(currentPage); // Fetch anime instead of TV shows or movies
      setAnime(data);
    };
    loadAnime();
  }, [currentPage]);

  const handlePageChange = (page) => {
    if (page > 0) setCurrentPage(page);
  };

  return (
    <div className="bg-gradient-to-b from-gray-900 to-black min-h-screen p-6">
      <NavBar />
      <h1 className="text-center text-2xl font-bold mb-6">Anime by Release Date</h1>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4">
        {anime.length > 0 ? (
          anime.map((show) => (
            <Link 
              to={`/watch/tv/${show.id}`} 
              key={show.id} 
              className="group relative"
            >
              <div className="
                relative overflow-hidden rounded-xl shadow-xl 
                transition-all duration-300 
                border border-transparent
                group-hover:border-gold-500/50 
                group-hover:scale-105 
                group-hover:shadow-2xl 
                bg-black/5"
              >
                {/* Poster Image */}
                <div className="relative pt-[150%] overflow-hidden"> 
                  <img 
                    src={`https://image.tmdb.org/t/p/w300/${show.poster_path}`} 
                    alt={show.name} 
                    className="absolute inset-0 w-full h-full object-cover 
                               opacity-90 group-hover:opacity-100 
                               transition-opacity duration-300 
                               transform group-hover:scale-110"
                  />
                  
                  {/* Hover Overlay */}
                  <div className="absolute inset-0 bg-black/0 group-hover:bg-black/30 
                                  transition-all duration-300 flex items-center justify-center">
                    <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      <FaPlay className="text-gold-500 text-4xl 
                                        transform hover:scale-110 
                                        transition-transform" />
                    </div>
                  </div>
                  
                  {/* Rating Overlay */}
                  <div className="absolute top-2 right-2 
                                  bg-black/60 rounded-full 
                                  px-2 py-1 flex items-center">
                    <FaStar className="text-gold-500 mr-1" />
                    <span className="text-xs text-gold-400 font-bold">
                      {show.vote_average ? show.vote_average.toFixed(1) : 'N/A'}
                    </span>
                  </div>
                </div>

                {/* Title Overlay */}
                <div className="absolute bottom-0 left-0 right-0 
                                bg-gradient-to-t from-black/80 to-transparent 
                                p-2">
                  <h3 className="text-xs font-semibold text-gold-400 truncate">
                    {show.name} {/* Use show.name for anime title */}
                  </h3>
                </div>
              </div>
            </Link>
          ))
        ) : (
          <p className="text-center text-gray-400">No anime found.</p>
        )}
      </div>
      <Pagination currentPage={currentPage} onPageChange={handlePageChange} />
      <Footer className="mt-12" />
    </div>
  );
};

export default Anime;
