import React, { useState, useEffect } from 'react';
import { FaFilm, FaTv, FaStar, FaPlay, FaDragon } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { fetchAnimeFromTMDB } from '../services/api';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/swiper-bundle.css';

const PopularA  = () => {
  const [popularAnime, setPopularAnime] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPopular  = async () => {
      const response = await fetchAnimeFromTMDB();
      setPopularAnime(response.slice(0, 15));
    };
    fetchPopular();
  }, []);

  const handleCardClick = (item) => {
    const mediaType =  'tv';
    navigate(`/watch/${mediaType}/${item.id}`);
  };

  return (
    <div className="relative w-full px-4 py-6">
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={20}
        slidesPerView={3}
        navigation={{
          nextEl: '.custom-next-button',
          prevEl: '.custom-prev-button',
        }}
        pagination={{
          clickable: true,
          el: '.custom-swiper-pagination',
          renderBullet: (index, className) => {
            return `<span class="${className} mx-1 inline-block w-3 h-3 rounded-full" style="background-color: #FFD700;"></span>`;
          },
        }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        breakpoints={{
          640: { slidesPerView: 1 },
          768: { slidesPerView: 2 },
          1024: { slidesPerView: 3 },
          1280: { slidesPerView: 4 },
        }}
      >
        {popularAnime.map((item) => {
          const typeIcon = item.media_type === 'tv' ? <FaTv /> : <FaDragon />;
          const backdropPath = item.backdrop_path 
            ? `https://image.tmdb.org/t/p/w500${item.backdrop_path}`
            : '/placeholder-image.png';

          return (
            <SwiperSlide key={item.id}>
              <div
                className="relative w-full h-[180px] rounded-xl overflow-hidden shadow-xl shadow-neutral-900 group cursor-pointer transform transition-all duration-300"
                onClick={() => handleCardClick(item)}
              >
                {/* Card backdrop */}
                <div
                  className="w-full h-full bg-cover bg-center transition-all duration-300"
                  style={{ backgroundImage: `url(${backdropPath})` }}
                ></div>

                {/* Dark overlay */}
                <div className="absolute inset-0 bg-black bg-opacity-50 group-hover:bg-opacity-70 transition-all duration-300"></div>

                {/* Overlay content */}
                <div className="absolute bottom-3 left-3 text-white z-10 space-y-1">
                  <div className="flex items-center space-x-2">
                    <h2 className="text-md font-bold truncate max-w-[200px]">
                      {item.title || item.name}
                    </h2>
                    <span className="text-yellow-500">{typeIcon}</span>
                  </div>
                  <div className="flex items-center space-x-1">
                    <FaStar className="text-yellow-500" />
                    <span className="text-sm">
                      {item.vote_average ? item.vote_average.toFixed(1) : 'N/A'}
                    </span>
                  </div>
                </div>

                {/* Play button on hover */}
                <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                  <button
                    className="bg-yellow-500 text-black p-3 rounded-full shadow-lg hover:bg-yellow-400 transition-all duration-300"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCardClick(item);
                    }}
                  >
                    <FaPlay size={20} />
                  </button>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>

      {/* Custom navigation buttons with z-index */}
      <button
        className="custom-prev-button absolute top-1/2 left-2 transform -translate-y-1/2 bg-black text-yellow-500 rounded-full w-10 h-10 flex items-center justify-center shadow-lg hover:bg-gray-800 z-20"
        aria-label="Previous Slide"
      >
        &larr;
      </button>
      <button
        className="custom-next-button absolute top-1/2 right-2 transform -translate-y-1/2 bg-black text-yellow-500 rounded-full w-10 h-10 flex items-center justify-center shadow-lg hover:bg-gray-800 z-20"
        aria-label="Next Slide"
      >
        &rarr;
      </button>

      {/* Custom pagination below cards */}
      <div className="custom-swiper-pagination mt-4 flex justify-center z-10"></div>
    </div>
  );
};

export default PopularA;
